<i18n locale="th" lang="yaml" >
customer_modal.search.placeholder : "ค้นหาลูกค้า (ชื่อ, เบอร์โทร)"
customer_modal.add.label : "เพิ่มลูกค้าใหม่"
customer.new.button : "เพิ่มลูกค้า"
customer.new.cancel : "ย้อนกลับ"
customer.new.title : "เพิ่มลูกค้าใหม่"
customer_modal.select.label : "เลือก"
customer_modal.unselect.label : "ยกเลิก"
customer_modal.title : "เลือกลูกค้า"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="cModalTitle"
		:footer="null"
		class="customer-modal"
		:mask-closable="false"
		@cancel="handleCancel">
		<div class="customer-modal-content">
			<div v-show="!addMode" class="command-pane">
				<a-input-search
					ref="_searchInput"
					v-model="searchTerm"
					class="command-search-input"
					:allow-clear="true"
					:placeholder="$t('customer_modal.search.placeholder')"
					@change="debounceSearchChange"/>
				<a-button v-if="canCreate"
					type="info"
					icon="plus"
					@click="handleNewClick">
					{{$t('customer_modal.add.label')}}
				</a-button>
			</div>
			<div class="command-content">
				<div v-if="addMode">
					<div class="page-section-title">
						{{$t('customer.new.title')}}
					</div>
					<CustomerForm
						mode="create"
						:customer="newCustomer"
						:form-error="newCustomerError"
						:parent-form-loading="newCustomerLoading"
						:submit-text="$t('customer.new.button')"
						:cancel-text="$t('customer.new.cancel')"
						@submit="submitNewCustomer"
						@cancel="cancelNewCustomer"/>
				</div>
				<div v-else>
					<a-list item-layout="vertical" :data-source="customers" :pagination="pagination"
						:loading="searchLoading"
						class="customer-list">

						<a-list-item slot="renderItem" key="customer.id" slot-scope="customer">
							<CustomerLiteCard
								size="small"
								:bordered="true"
								:disabled="isExcludedCustomer(customer)"
								:selected="isSelectedCustomer(customer)"
								:customer="customer">
								<span slot="action">
									<a-button v-if="isSelectedCustomer(customer)"
										@click="onUnselectCustomer">
										{{$t('customer_modal.unselect.label')}}
									</a-button>
									<a-button v-else @click="onSelectCustomer($event,customer)">
										{{$t('customer_modal.select.label')}}
									</a-button>
								</span>

							</CustomerLiteCard>
						</a-list-item>
					</a-list>
				</div>
			</div>
		</div>
	</a-modal>
</template>

<script>
import axios from "axios"
import debounce from "lodash/debounce"
import CustomerForm from "./CustomerForm.vue"
import CustomerLiteCard from "./CustomerLiteCard.vue"
import {Input,List} from "ant-design-vue"
import ApiError from '@utils/errors/ApiError'
import {isStringEmpty} from "@utils/stringUtil"
export default {
	components : {
		"a-input-search" : Input.Search,
		"a-list" : List, "a-list-item" : List.Item,
		CustomerForm , CustomerLiteCard,
	} ,
	model : {
		prop : "selected" ,
		event : "select"
	} ,
	props : {
		visible : {
			type : Boolean ,
			default : false ,
		} ,
		modalTitle : {
			type : String ,
			default : null
		} ,
		canCreate : {
			type : Boolean,
			default : false,
		} ,
		createMode : {
			type : String,
			default : 'validate' ,
		} ,
		excludedList : {
			type : null,
			default : () => []
		} ,
		selected : {
			type : null,
			default : () => {
				return {
					mode : "none" ,
					customer : {}
				}
			}
		}
	} ,
	data() {
		return {
			addMode : false ,
			newCustomerError : {} ,
			newCustomerLoading : false,
			searchTerm : '' ,
			searchLoading : false,
			customers : [] ,
			pagination : {
				pageSize : 5 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
				onChange : page => {
					this.fetchCustomer(page)
				}
			} ,
		}
	} ,
	computed: {
		cModalTitle() {
			return isStringEmpty(this.modalTitle) ? this.$t('customer_modal.title') : this.modalTitle
		} ,
		newCustomer() {
			if (this.selected.mode == 'new') {
				return this.selected.customer
			} else {
				return {}
			}
		}
	} ,
	watch : {
		visible() {
			if (this.visible) {
				if (this.selected.mode == 'new') {
					// show add mode
					this.addMode = true;
					this.searchTerm = ""
					this._handleSearchChange()
				} else if (this.selected.mode == 'select') {
					this.addMode = false
					this.searchTerm = this.selected.customer.name
					this.pagination.current = 1;
					this.pagination.total = 1;
					this.customers = [this.selected.customer]
				} else {
					this.addMode = false
					this.searchTerm = ""
					this._handleSearchChange()
				}
				if (!this.addMode) {
					this.$nextTick(() => {
						setTimeout(()=>{this.$refs._searchInput.$el.querySelector('input').select()},50)
					})
				}
			}
		} ,
	} ,
	created() {
		this.debounceSearchChange = debounce(this._handleSearchChange,300)
	} ,
	methods : {
		isSelectedCustomer(customer) {
			return (this.selected.mode == 'select') && (this.selected.customer.id == customer.id)
		} ,
		isExcludedCustomer(customer) {
			if (this.excludedList && this.excludedList.length > 0) {
				return this.excludedList.find((excluded) => excluded.id == customer.id) !== undefined
			} else {
				return false;
			}
		} ,
		_handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1;
			this.fetchCustomer();
		} ,
		fetchCustomer(toPage=null) {
			if (toPage === null)
				toPage = this.pagination.current
			this.searchLoading = true
			const searchParamsData = {
				search : {
					page_size : this.pagination.pageSize ,
					current_page : toPage,
				} ,
				filter : {
					keyword : this.searchTerm
				}
			}
			axios.get("/api/customers/search",{params : searchParamsData}).then((response) => {
				const page = response.data.data.pagination;
				this.customers = response.data.data.customers;
				this.pagination.current = toPage;
				this.pagination.total = page.total;
			}).catch((error)=>{
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(()=>{
				this.searchLoading = false
			})
		} ,
		submitNewCustomer(eventData) {
			this.newCustomerLoading = true
			let url = '/api/customers/create-validate'
			let selectMode = 'new'
			if (this.createMode == 'create') {
				// send create
				url = '/api/customers/create'
				selectMode = 'select'
			}
			axios.post(url,eventData.customer).then((response) => {
				const customer = response.data.data.customer

				const data = {
					mode : selectMode,
					customer : customer
				}

				// emit 2 event , select and new customer
				this.$emit('select',data)

			}).catch((error) => {
				this.newCustomerError = error
			}).finally(()=>{
				this.newCustomerLoading = false
			})
		} ,
		cancelNewCustomer() {
			this.addMode = false
		} ,
		handleNewClick() {
			this.newCustomerError = {}
			this.addMode = true
		} ,

		handleCancel() {
			this.$emit('close');
			this.addMode = false
			this.searchTerm = '';
		} ,
		onUnselectCustomer() {
			const data = {
				mode : 'none',
				customer : {}
			}
			this.$emit('select',data)
		} ,
		onSelectCustomer(event,selected) {
			const data = {
				mode : 'select',
				customer : {...selected}
			}
			this.$emit('select',data)
		} ,
	}
}
</script>
<style lang="less" scoped>
.command-pane {
	display: flex;
	.command-search-input {
		flex-grow: 1;
	}
	button {
		margin-left : 4px;
	}
}
.customer-list {
	min-height : 125px;
	padding-top : 24px;
	.ant-list-item {
		padding-top : 0;
		border-bottom : none !important;
	}
}
</style>
