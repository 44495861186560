<i18n locale="th" lang="yaml" src="@i18n/customer/customer.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
customer.field.address.placeholder : "ที่อยู่ในการติดต่อลูกค้า"
customer.field.phone.placeholder : "เบอร์ที่ใช้ติดต่อได้"
customer.field.email.placeholder : "อีเมล์ที่ใช้ติดต่อ"
customer.field.nick_name.placeholder : "ชื่อที่ใช้เรียกกัน"
</i18n>

<template>
	<a-form
		layout="vertical"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<a-form-item
			:label="$t('customer.field.customer_type')">
			<a-radio-group v-decorator="[
					'customer_type' ,
					{
						rules: [
							{ required: true, message: $tt('validate.required','customer.field.customer_type') },
						],
						initialValue: 'people',
					},
				]"
				button-style="solid"
				@change="handleCustomerTypeChange">
				<a-radio-button value="people">
					{{$tenum('customer_type','people')}}
				</a-radio-button>
				<a-radio-button value="juristic">
					{{$tenum('customer_type','juristic')}}
				</a-radio-button>
				<a-radio-button value="others">
					{{$tenum('customer_type','others')}}
				</a-radio-button>
			</a-radio-group>
		</a-form-item>
		<div class="form-row">
			<a-form-item :label="nameLabel">
				<a-input
				v-decorator="[
					'name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required',nameLabel) },
					] },
				]"
				type="text"
				:placeholder="nameLabel"/>
			</a-form-item>

		</div>
		<div class="form-row">
			<a-form-item :label="$t('customer.field.nick_name')">
				<a-input
					v-decorator="['nick_name']"
					type="text"
					:placeholder="$t('customer.field.nick_name.placeholder')"/>
			</a-form-item>
			<div class="form-row-space" />
			<a-form-item :label="identityNoLabel">
				<a-input
					v-decorator="[
						'identity_no',
					]"
					:placeholder="identityNoLabel"/>
			</a-form-item>
		</div>

		<div class="form-row">
			<a-form-item :label="$t('customer.field.phone')">
			<a-input
				v-decorator="[
					'phone' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','customer.field.phone') },
					] },
				]"
				type="tel"
				:max-length="20"
				:placeholder="$t('customer.field.phone.placeholder')">
				<a-icon slot="prefix" type="phone" />
			</a-input>
			</a-form-item>
			<div class="form-row-space" />
			<a-form-item :label="$t('customer.field.email')">
				<a-input
					v-decorator="[
						'email',
					]"
					type="email"
					:placeholder="$t('customer.field.email.placeholder')">
					<a-icon slot="prefix" type="mail" />
				</a-input>
			</a-form-item>
		</div>
		<hr />
		<a-form-item :label="$t('customer.field.address')">
			<a-textarea
				v-decorator="['address']"
				:auto-size="{ minRows: 2,maxRows: 5 }"
				:placeholder="$t('customer.field.address.placeholder')">
			</a-textarea>
		</a-form-item>

		<ProvinceSelect
			ref="provinceSelect"
			v-model="address"
			:require-province="true"
			:show-postal-code="true"/>
		<hr />
		<div
			class="text-right">
			<a-button
				@click="handleCancel">
				{{cancelTextComputed}}
			</a-button>
			<a-button
				:loading="formLoading"
				type="info"
				html-type="submit"
				class="btn-submit">
				<a-icon type="save" /> {{submitTextComputed}}
			</a-button>
		</div>
	</a-form>
</template>

<script>
import HasAntdFormMixin from "@mixins/HasAntdFormMixin.vue"
import ProvinceSelect from "@components/input/ProvinceSelect.vue"
import {Radio} from "ant-design-vue"
import { isStringEmpty } from '@utils/stringUtil'
import { replaceProperty } from '../../utils/objectUtil'
import { updateFieldsValue } from '../../utils/formUtil'

export default {
	components : {
		"a-radio-group" : Radio.Group , "a-radio-button" : Radio.Button ,
		ProvinceSelect ,
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		mode : {
			type : String,
			default : 'update'
		} ,
		customer : {
			type : null,
			default : () => []
		} ,
		formError : {
			type : null,
			default : null
		} ,
		parentFormLoading : {
			type : Boolean ,
			default : false,
		} ,
		submitText : {
			type : String,
			default : null
		} ,
		cancelText : {
			type : String,
			default : null
		}
	} ,
	data() {
		return {
			formLoading : this.parentFormLoading,
			customerType : null,
			address : {
				province : undefined,
				district : undefined,
				subdistrict: undefined,
				postal_code: undefined,
			},
		}
	} ,
	computed : {
		submitTextComputed() {
			return isStringEmpty(this.submitText) ? this.$t('common.save') : this.submitText
		} ,
		cancelTextComputed() {
			return isStringEmpty(this.cancelText) ? this.$t('common.cancel') : this.cancelText
		} ,
		identityNoLabel() {
			switch(this.customerType) {
				case 'juristic' :
					return this.$t('customer.field.identity_no.juristic')
				case 'people' :
					return this.$t('customer.field.identity_no.people')
				default :
					return this.$t('customer.field.identity_no')
			}
		} ,
		nameLabel() {
			switch(this.customerType) {
				case 'juristic' :
					return this.$t('customer.field.name.juristic')
				case 'people' :
					return this.$t('customer.field.name.people')
				default :
					return this.$t('customer.field.name')
			}
		}
	} ,
	watch : {
		customer : {
			handler(newVal,oldVal) {
				this.updateCustomer()
			} ,
			deep : true
		} ,
		formError() {
			this.formSubmitErrors(this.formError)
		} ,
	} ,
	mounted() {
		this.updateCustomer()
	},
	methods : {
		updateCustomer() {
			if (this.customer && !isStringEmpty(this.customer.customer_type)) {
				this.customerType = this.customer.customer_type
			} else {
				this.customerType = "people"
			}
			this.address = replaceProperty(this.address,this.customer)
			updateFieldsValue(this.formObj,this.customer)
		},
		handleCustomerTypeChange(event) {
			this.customerType = event.target.value
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!this.$refs.provinceSelect.validate() && !err) {
					const formData = {
						...this.customer,
						...values,
						...this.address,
					};
					const submitData = {
						mode : this.mode,
						customer : formData,
					}
					this.$emit("submit",submitData)
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		handleCancel() {
			this.$emit("cancel")
		}
	}
}
</script>

<style lang="less" scoped>
.form-row {
	display : flex;
	> .ant-form-item {
		flex-grow: 1;
	}
	.form-row-space {
		flex-grow: 0;
		width : 24px;
	}
}
.province-select-container::v-deep > div.province-block {
	flex-grow: 1;
	margin : 0 0 24px !important;
	&:nth-child(odd) {
		margin-right : 24px !important;
	}

}
.province-select-container::v-deep > .has-error {
	width : 100%;
	margin : -16px 0 24px;
}
</style>
