var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"layout":"vertical","form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.$t('customer.field.customer_type')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'customer_type' ,
				{
					rules: [
						{ required: true, message: _vm.$tt('validate.required','customer.field.customer_type') },
					],
					initialValue: 'people',
				},
			]),expression:"[\n\t\t\t\t'customer_type' ,\n\t\t\t\t{\n\t\t\t\t\trules: [\n\t\t\t\t\t\t{ required: true, message: $tt('validate.required','customer.field.customer_type') },\n\t\t\t\t\t],\n\t\t\t\t\tinitialValue: 'people',\n\t\t\t\t},\n\t\t\t]"}],attrs:{"button-style":"solid"},on:{"change":_vm.handleCustomerTypeChange}},[_c('a-radio-button',{attrs:{"value":"people"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tenum('customer_type','people'))+"\n\t\t\t")]),_vm._v(" "),_c('a-radio-button',{attrs:{"value":"juristic"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tenum('customer_type','juristic'))+"\n\t\t\t")]),_vm._v(" "),_c('a-radio-button',{attrs:{"value":"others"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tenum('customer_type','others'))+"\n\t\t\t")])],1)],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('a-form-item',{attrs:{"label":_vm.nameLabel}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'name' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required',_vm.nameLabel) },
				] },
			]),expression:"[\n\t\t\t\t'name' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required',nameLabel) },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.nameLabel}})],1)],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('a-form-item',{attrs:{"label":_vm.$t('customer.field.nick_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nick_name']),expression:"['nick_name']"}],attrs:{"type":"text","placeholder":_vm.$t('customer.field.nick_name.placeholder')}})],1),_vm._v(" "),_c('div',{staticClass:"form-row-space"}),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.identityNoLabel}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'identity_no',
				]),expression:"[\n\t\t\t\t\t'identity_no',\n\t\t\t\t]"}],attrs:{"placeholder":_vm.identityNoLabel}})],1)],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('a-form-item',{attrs:{"label":_vm.$t('customer.field.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'phone' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','customer.field.phone') },
				] },
			]),expression:"[\n\t\t\t\t'phone' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','customer.field.phone') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"tel","max-length":20,"placeholder":_vm.$t('customer.field.phone.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_vm._v(" "),_c('div',{staticClass:"form-row-space"}),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('customer.field.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
				]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t]"}],attrs:{"type":"email","placeholder":_vm.$t('customer.field.email.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('customer.field.address')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address']),expression:"['address']"}],attrs:{"auto-size":{ minRows: 2,maxRows: 5 },"placeholder":_vm.$t('customer.field.address.placeholder')}})],1),_vm._v(" "),_c('ProvinceSelect',{ref:"provinceSelect",attrs:{"require-province":true,"show-postal-code":true},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("\n\t\t\t"+_vm._s(_vm.cancelTextComputed)+"\n\t\t")]),_vm._v(" "),_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.formLoading,"type":"info","html-type":"submit"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.submitTextComputed)+"\n\t\t")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }